import * as React from 'react'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { SearchBar } from '../components/SearchBar'

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    markdownRemark: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
        featuredImage: string
      }
    }
  }
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const Title = styled.h2`
    font-family: Montserrat;
    font-weight: bold;
    color: #000;
    margin-bottom: 1em;
  `

  const ContentContainer = styled.div`
    color: #000;
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    align-items: stretch;
    width: 100%;
  `

  const TextContainer = styled.div`
    margin-left: ${isMobile ? 0 : '1em'};
    font-size: 18px;
    font-weight: normal;
  `

  const ImageContainer = styled.div`
    width: ${isMobile ? '100%' : '500px'};
    min-width: ${!isMobile ? '500px' : 0};
    margin-bottom: 1em;
  `;

  const Image = styled.img`
    width: 100%;
  `;

  return (
    <Page>
      <Container>
        <Title>{data.markdownRemark.frontmatter.title}</Title>
        {/* eslint-disable-next-line react/no-danger */}
        <ContentContainer>
          {data.markdownRemark.frontmatter.featuredImage &&
            <ImageContainer>
              <Image src={data.markdownRemark.frontmatter.featuredImage} />
            </ImageContainer>
          }
          <TextContainer dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </ContentContainer>
      </Container>
    </Page>
  )
}

export default PageTemplate

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        featuredImage
      }
    }
  }
`
